import get from 'lodash.get';
import globals from '../globals';
import embeddedChatConfigurations from '../embeddedChatConfigurations';
import { globalEventBus } from '../../../modules/event-bus';

/* eslint-disable prefer-arrow-callback */
export default {
  name: 'vx-embedded-chat',
  data() {
    return {
      globals,
      orderCode: '',
    };
  },
  props: {
    // Flag to enable/ disable the sticky live chat button on all pages
    embeddedChatEnabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
  },
  mounted() {
    // Order number to be populated for salesforce chat
    globalEventBus.$on('order-details', (orderDetails) => {
      this.orderCode = orderDetails;
    });
    const isEstores = globals.isEStores(); // or any other boolean value
    const afterOtLoaded = () => {
      // this.$el.addEventListener('keyup', (event) => { if (event.keyCode === 27) { window.embedded_svc.inviteAPI.inviteButton.rejectInvite(); } });
      const chatEwsScript = document.createElement('script');
      chatEwsScript.setAttribute('src', 'https://service.force.com/embeddedservice/5.0/esw.js');
      let isCookiesBlocked = true; // initialize as true to ensure chatEwsScript type is set correctly if window.OnetrustActiveGroups does not exist

      if (isEstores || globals.checkIsOpted()) {
        isCookiesBlocked = false;
      }
      const environment = get(globals, ['siteConfig', 'serverEnvironment']) === 'prd' ? 'prd' : 'stg';
      if (environment === 'prd' && isCookiesBlocked) {
        chatEwsScript.setAttribute('type', 'text/plain');
      }
      chatEwsScript.setAttribute('class', 'optanon-category-4');
      // chatEwsScript.setAttribute('onload', 'chatScriptLoaded');
      const context = this;
      // eslint-disable-next-line no-multi-assign
      chatEwsScript.onload = chatEwsScript.onreadystatechange = function() {
        if (!window.embedded_svc) {
          const embeddedChatConfig = context.getEmbeddedChatConfig();
          const { salesForceScript = '' } = embeddedChatConfig;
          const s = document.createElement('script');
          s.setAttribute('src', salesForceScript);
          s.onload = function() {
            context.initESW(null);
          };
          document.body.appendChild(s);
        } else {
          context.initESW('https://service.force.com');
        }
      };
      const headTag = document.getElementsByTagName('HEAD');
      if (headTag && headTag.length > 0 && context.embeddedChatEnabled) {
        headTag[0].appendChild(chatEwsScript);
      }
      context.helpButtonLoaded();
      context.handleChatForm();
    };

    let otTimer = {};
    const otChecker = function() {
      const environment = get(globals, ['siteConfig', 'serverEnvironment']) === 'prd' ? 'prd' : 'stg';
      if (isEstores || environment !== 'prd' || !(window.OnetrustActiveGroups == null)) {
        clearInterval(otTimer);
        afterOtLoaded();
      }
    };
    otTimer = setInterval(otChecker, 500);
  },
  methods: {
    setCookie(name, value) {
      const today = new Date();
      // eslint-disable-next-line no-mixed-operators
      const expiry = new Date(today.getTime() + 365 * 24 * 3600 * 1000); // cookie stored for 365 days
      document.cookie = `${name}=${escape(value)}; path=/; expires=${expiry.toGMTString()}`;
    },
    getCookie(name) {
      const re = new RegExp(`${name}=([^;]+)`);
      const value = re.exec(document.cookie);
      return (value != null) ? unescape(value[1]) : '';
    },
    helpButtonLoaded() {
      const helpButton = this.$el.querySelector('.helpButton');
      if (!helpButton) {
        window.setTimeout(this.helpButtonLoaded, 500);
        return;
      }
      const height = helpButton.clientHeight;
      const width = helpButton.clientWidth;
      window.parent.postMessage({
        frameHeight: height,
        frameWidth: width,
      }, '*'); // Used a * but for security reasons you can plug in the domain name.
    },
    initESW(gslbBaseURL) {
      const embeddedChatConfig = this.getEmbeddedChatConfig();
      const {
        initParam1 = '', initParam2 = '', initParam4 = '', initParam5 = '', initParam6 = {},
      } = embeddedChatConfig;
      window.embedded_svc.settings.displayHelpButton = true;
      window.embedded_svc.settings.language = '';
      // Add Privacy Chat Snippet
      let show = false;
      window.embedded_svc.addEventHandler('afterMaximize', function() {
        if (!show) {
          show = true;
          const li2 = document.createElement('div');
          li2.style.color = 'white';
          li2.style.backgroundColor = '#005290';
          li2.style.padding = '5px';
          li2.appendChild(document.createTextNode('By using our chat you agree to our company’s '));
          const a = document.createElement('a');
          const linkText = document.createTextNode('Privacy Policy');
          a.appendChild(linkText);
          a.title = 'Koch Privacy Policy';
          a.href = 'https://privacypolicy.kochind.com/';
          a.style.color = 'white';
          li2.appendChild(a);
          const sidebar = document.querySelector('div.formContent.embeddedServiceSidebarForm');
          sidebar.insertBefore(li2, sidebar.childNodes[0]);
        }
      });

      window.embedded_svc.addEventHandler('afterDestroy', function() {
        show = false;
      });

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';
      window.embedded_svc.init(
        initParam1,
        initParam2,
        gslbBaseURL,
        initParam4,
        initParam5,
        initParam6,
      );
    },
    rejectInvite() {
      window.embedded_svc.inviteAPI.inviteButton.rejectInvite();
    },
    acceptInvite() {
      window.embedded_svc.inviteAPI.inviteButton.acceptInvite();
    },
    exitInvite() {
      window.embedded_svc.inviteAPI.inviteButton.rejectInvite();
    },
    getEmbeddedChatConfig() {
      const brandType = globals.isB2B() ? 'b2b' : 'b2c';
      const environment = get(globals, ['siteConfig', 'serverEnvironment']) === 'prd' ? 'prd' : 'stg';
      return get(embeddedChatConfigurations, [brandType, environment]);
    },
    handleChatForm() {
      const self = this;
      const preFormObserver = new MutationObserver(function() {
        const subjectField = document.querySelector('.embeddedServiceLiveAgentSidebarFeature #Subject');
        // Fields to be populated for salesforce chat
        const firstName = document.querySelector('.embeddedServiceLiveAgentSidebarFeature #FirstName');
        const lastName = document.querySelector('.embeddedServiceLiveAgentSidebarFeature #LastName');
        const emailAddress = document.querySelector('.embeddedServiceLiveAgentSidebarFeature #Email');
        const phoneNumber = document.querySelector('.embeddedServiceLiveAgentSidebarFeature #Phone');
        const companyName = document.querySelector('.embeddedServiceLiveAgentSidebarFeature #Company_Name__c');
        if (subjectField !== null) {
          subjectField.addEventListener('input', function(event) {
            localStorage.setItem('chat_subject', event.target.value);
          });
          this.disconnect();
        }

        // Prefill fields for salesforce chat only if the user is logged in
        if (self.globals.getIsLoggedIn()) {
          if (firstName !== null) {
            firstName.value = self.globals.userInfo.firstName;
            firstName.dispatchEvent(new Event('change', { bubbles: true }));
          }
          if (lastName !== null) {
            lastName.value = self.globals.userInfo.lastName;
            lastName.dispatchEvent(new Event('change', { bubbles: true }));
          }
          if (emailAddress !== null) {
            emailAddress.value = self.globals.userInfo.email;
            emailAddress.dispatchEvent(new Event('change', { bubbles: true }));
          }
          if (phoneNumber !== null) {
            phoneNumber.value = self.globals.userInfo.contactNumber;
            phoneNumber.dispatchEvent(new Event('change', { bubbles: true }));
          }
          if (companyName !== null) {
            companyName.value = self.globals.userInfo.unit;
            companyName.dispatchEvent(new Event('change', { bubbles: true }));
          }
          if (subjectField !== null) {
            if (self.orderCode !== '') {
              subjectField.value = self.orderCode;
              subjectField.dispatchEvent(new Event('change', { bubbles: true }));
            } else {
              subjectField.value = '';
              subjectField.dispatchEvent(new Event('change', { bubbles: true }));
            }
          }
        }
      });
      preFormObserver.observe(document, {
        attributes: false, childList: true, characterData: false, subtree: true,
      });

      const chatObserver = new MutationObserver(function() {
        const textarea = document.querySelector('.embeddedServiceLiveAgentStateChatInputFooter textarea');
        if (textarea != null) {
          textarea.value = localStorage.getItem('chat_subject');
          const ke = new KeyboardEvent('keydown', {
            bubbles: true, cancelable: true, keyCode: 13,
          });
          textarea.dispatchEvent(ke);
          this.disconnect();
        }
      });
      chatObserver.observe(document, {
        attributes: false, childList: true, characterData: false, subtree: true,
      });
    },
  },
};
